<!-- eslint-disable -->
<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="240"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list
      dense
      nav
    >
      <v-list-item to="/publichome"  >
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <img
            src="../../assets/inprem_favicon.png"
            center
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-2"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  <!-- Top of interactive drawer items starts here  -->

    <v-divider class="mb-1" />

    <v-divider class="mb-2" />

    <v-list
      dense
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!-- ***** Debug Instance ***** -->
      <v-btn
        color="red"
        text 
        @click='debuginstance++'
        >
        Debug Instance
      </v-btn>
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import { mapState, } from 'vuex'
import SettingsGroupService from '../../services/SettingsGroupService'
import { EventBus } from '../../event-bus.js'

export default {
  name: 'CVDrawer',
  components: {SettingsGroupService},
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

//************************
// DATA
//************************
data () { return {
  cvdkey: 0,
  debuginstance: 0,
  // {// HOME ***********************************************
  //   icon: 'mdi-home',
  //   title: 'HOME',
  //   to: '/',
  // },

  items: [

  {// Saved Reports group*********************************
    group: '/basereports',
    // icon: 'mdi-view-dashboard',
    title: 'Saved Reports',
    to: '/',
    children: [
    ],
  },

  {// Base Reports group *********************************
    group: '/basereports',
    // icon: 'mdi-view-dashboard',
    title: 'Base Reports',
    to: '/',
    children: [
      {
        title: 'Combo Table 1',
        to: 'combotable/0',
        icon: 'mdi-table-large'
      },
      {
        title: 'Combo Chart 1',
        to: 'combochart1/0',
        icon: 'mdi-chart-bar'
      },
      {
        title: 'Well Chart 1',
        to: 'wellchart1/0',
        icon: 'mdi-chart-bar'
      },
      {
        title: 'Weather Dashboard',
        to: 'weatherdashboard',
        icon: 'mdi-weather-partly-cloudy'
      },
      {
        title: 'Meter Checks Table',
        to: 'metercheckstable/0',
        icon: 'mdi-clipboard-check'
      },
    ],
  },
    {// Dashboards group
    group: '/dashboards',
    title: 'Dashboards',
    to:'/dashboards',
    children: [
      {
        title: 'Water/Propane Dashboard',
        to: 'maindashboard',
        icon: 'mdi-gauge'
      },
    ]
  },
{// Utility group*********************************
    group: '/utilities',
    // icon: 'mdi-view-dashboard',
    title: 'Utilities',
    to: '/',
    children: [
      {
        title: 'Datalog Import',
        to: 'datalogimport',
        icon: 'mdi-arrow-collapse'
      },
    ],
  },
  {// Help group*********************************
    group: '/docs',
    // icon: 'mdi-view-dashboard',
    title: 'Help',
    to: '/',
    children: [
      {
        title: 'About',
        to: 'about',
        icon: 'mdi-information'
      },
      {
        title: 'User Help',
        to: 'userhelp',
        icon: 'mdi-help-circle'
      },
      {
        title: 'Tech Notes',
        to: 'technotes',
        icon: 'mdi-file-document'
      },
    ],
  },
  ],
}},

//************************
// WATCH
//************************

computed: {
  ...mapState(['barColor']),
  drawer: {
    get () {
      return this.$store.state.drawer
    },
    set (val) {
      this.$store.commit('SET_DRAWER', val)
      // console.log('val= ', val, 'drawer= ', this.drawer)
    },
  },
  computedItems () {
    var _items = this.items.map(this.mapItem)
    // console.log('items= ', _items)
    return _items
  },
  profile () {
    return {
      avatar: true,
      group: '',
      title: this.$t('avatar'),
      children: [
        {
          href: '',
          title: this.$t('my-profile'),
        },
        {
          to: '',
          title: this.$t('edit-profile'),
        },
        {
          to: '',
          title: this.$t('settings'),
        },
      ],
    }
  },
},

//************************
// WATCH
//************************
watch: {
  '$vuetify.breakpoint.smAndDown' (val) {
    this.$emit('update:expandOnHover', !val)
  },
  debuginstance (val) {
    debugger
  }, 
},

//************************
// METHODS
//************************
methods: {
//***** Load Saved Reports
  async loadSavedReports() {
    try {
      const userUid = this.$store.state.loggedin.userUid
      const savedreports = await SettingsGroupService.getAllSettingsGroups(userUid)
      let savedreport, index = 0
      let children = []
      //
      for (savedreport of savedreports) {
        let child = this.getReportInfo(savedreport.sgTypeId)
        const settings = JSON.parse(savedreport.sgSettings)
        child.title = settings.reportName
        child.to += '/' + savedreport.sgId
        children.push(child)
      // Put this user's saved reports into the nav drawer
      index++
    }
      // debugger
      this.items[0].children = children
    } catch (err) {
      console.log('CVDrawer > Load Saved Reports ERROR = ', err.message);
    }
  },
//***** Return drawer info from report type (pass in as number)
  getReportInfo (typeid) {
    const childinfo = {basetitle: '', title: '', to: '', icon: ''}
    switch (typeid) {
      case 100: {
        childinfo.basetitle = 'Combo Table',
        childinfo.to = 'combotable'
        childinfo.icon = 'mdi-table-large'
        break;
        }
      case 101: {
        childinfo.basetitle = 'Combo Chart 1',
        childinfo.to = 'combochart1'
        childinfo.icon = 'mdi-chart-bar'
        break;
        }
      case 104: {
        childinfo.basetitle = 'Meter Checks Table',
        childinfo.to = 'metercheckstable'
        childinfo.icon = 'mdi-clipboard-check'
        break;
        }
      case 105: {
        childinfo.basetitle = 'Well Chart 1',
        childinfo.to = 'wellchart1'
        childinfo.icon = 'mdi-clipboard-check'
        break;
        }
      }
    return childinfo
  },

  mapItem (item) {
    return {
      ...item,
      children: item.children ? item.children.map(this.mapItem) : undefined,
      // title: this.$t(item.title),    // how it was
      title: item.title
    }
  },
},
//************************
// LIFECYCLE HOOKS
//************************
mounted () {
// Load Saved Reports
  this.loadSavedReports()
  EventBus.$on('updateCVDrawer',() => {this.loadSavedReports() })
}

} // Export default end bracket
//************ END of JAVASCRIPT **************//
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
